import { $fetch, $post, $delete } from '@/services/axios';

// batch
export function fetch() {
  return $fetch('system/batch/jobs');
}

export function executeBatch(jobName) {
  return $post(`system/batch/job/${jobName}/schedule`);
}

export function validateBatch(model) {
  return $post('system/batch/job/validate', model);
}

export function addBatch(model) {
  return $post('system/batch/job/schedule', model);
}

export function getSteps(jobExecutionId) {
  return $fetch(`system/batch/job/execution/steps`, { jobExecutionId });
}

// batch history
export function getExecutions(jobName, pageNo, pageSize) {
  return $fetch(`system/batch/job/${jobName}/executions`, { pageNo, pageSize });
}

export function removeExecution(jobInstanceId) {
  return $delete(`system/batch/job/${jobInstanceId}`);
}

export function restartExecution(jobExecutionId) {
  return $post(`system/batch/job/reschedule`, { jobExecutionId });
}
