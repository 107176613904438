<template>
  <n-modal v-bind="$props" :width="900">
    <Form ref="form" label-position="right" :model="model" class="p-form" @submit.native.prevent>
      <Row>
        <Col class="p-label" span="5">
          {{ $t('label.jobName') }}
        </Col>
        <Col span="19">
          <Row>
            <Col span="19">
              <n-field-text v-model="model.jobName" :error="error.jobName" :disabled="disabled"></n-field-text>
            </Col>
            <Col span="5"></Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col class="p-label" span="5">
          {{ $t('label.jobParameters') }}
        </Col>
        <Col span="19">
          <Row v-for="(item, index) in model.jobParameters" :key="index">
            <Col span="19">
              <Row>
                <Col span="11">
                  <n-field-text v-model="item.key" placeholder="key" :disabled="disabled" :error="error.jobParameters"></n-field-text>
                </Col>
                <Col span="11" offset="2">
                  <n-field-text v-model="item.value" placeholder="value" :disabled="disabled" :error="error.jobParameters"></n-field-text>
                </Col>
              </Row>
            </Col>
            <Col v-if="index === model.jobParameters.length - 1" span="5">
              <n-button-icon
                class="p-button-icon ml-3 mr-3"
                custom-icon="fas fa-plus"
                :disabled="disabled"
                @on-click="onBtnAddParameter"
              />
              <n-button-icon
                v-if="index > 0"
                class="p-button-icon"
                custom-icon="fas fa-trash-alt"
                :disabled="disabled"
                @on-click="onBtnRemoveParameter"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { addBatch, validateBatch } from '@/api/system/system-batch';

export default NModal.extend({
  name: 'RunBatchDialog',
  components: { NModal },

  data() {
    return {
      n_Model: {},
    };
  },

  methods: {
    doSubmit() {
      return addBatch(this.n_Model);
    },

    onBtnAddParameter() {
      this.model.jobParameters.push({ key: '', value: '' });
      this.$nextTick(() => {
        this.$refs.form.$el.scrollTop = 1000;
      });
    },

    onBtnRemoveParameter(index) {
      this.model.jobParameters.pop();
    },

    doValidate() {
      let jobParameters = this.model.jobParameters;
      let invalid = jobParameters.every(o => [null, ''].includes(o.key) && [null, ''].includes(o.value));
      if (invalid) {
        jobParameters = [];
      }

      const model = {
        jobName: this.model.jobName,
        jobParameters: JSON.stringify(jobParameters),
      };

      return validateBatch(model).then(res => {
        this.n_Model = model;
        return res;
      });
    },
  },
});
</script>

<style scoped lang="scss">
.p-label {
  text-align: right;
  padding: 6px 20px;
  font-weight: bold;
}

.p-button-icon {
  margin-top: 6px;
  width: 32px;
  height: 26px;
}
.p-form {
  max-height: 500px;
  overflow-y: auto;
}

::v-deep .ivu-form-item {
  margin-bottom: 20px;
}
</style>
