<template>
  <index-content>
    <template #options>
      <nb-refresh @on-click="onBtnRefresh" />
      <nb-schedule @on-click="onBtnAddBatch" />
    </template>

    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="jobName">{{ $t('label.jobName') }}</th>
            <th class="status">{{ $t('label.status') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.jobName | hyphen }}</td>
            <td :class="['n-status-' + row.status.toLowerCase()]">{{ row.status }}</td>
            <td>{{ row.lastUpdated | datetime }}</td>
            <td class="n-button-box">
              <n-button text="history" custom-icon="fas fa-info-circle" @on-click="onBtnHistory(row)" />
              <nb-confirm text="schedule" @on-ok="onBtnSchedule(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import SystemView from './view';
import { mapMutations } from 'vuex';
import ModalRunBatch from './ModalRunBatch';
import { fetch, executeBatch } from '@/api/system/system-batch';

export default SystemView.extend({
  name: 'SystemBatch',

  data() {
    return {
      preload: true,
      maxTableHeight: 9999,
    };
  },

  methods: {
    ...mapMutations('access', ['pushMenu']),

    doLoad() {
      return fetch();
    },

    parse(rows) {
      this.records = rows;
    },

    onBtnRefresh() {
      this.load();
    },

    onBtnSchedule(row) {
      const { jobName } = row;
      return executeBatch(jobName);
    },

    onBtnHistory(row) {
      this.pushMenu({ url: `/system/batch/${row.jobName}/history` });
    },

    onBtnAddBatch() {
      this.createModal(ModalRunBatch, { model: { jobParameters: [{ key: '', value: '' }] }, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 770px;

  th.status {
    width: 135px;
  }

  th.rw-action {
    width: 10.5rem;
  }

  th.ro-action {
    width: 5.5rem;
  }
}
</style>
